import { BigNumber } from 'ethers'
import { useQuery, UseQueryResult } from 'react-query'
import { getMilliFromMinutes } from 'utils/rq-settings'
import { useAppContext } from '../components/app-context'

interface Options {
	gnosis?: boolean | 'auto'
}

const useBalance = ({ gnosis = false }: Options = {}): UseQueryResult<BigNumber> => {
	const { walletProvider, safeSdk } = useAppContext()

	return useQuery(
		'balance',
		async (): Promise<BigNumber> => {
			const useGnosis = gnosis === 'auto' ? Boolean(safeSdk) : gnosis
			const signer = useGnosis ? safeSdk : walletProvider?.getSigner()

			if (!signer) return BigNumber.from(0)
			return signer.getBalance()
		},
		{
			enabled: !!walletProvider,
			staleTime: getMilliFromMinutes(1),
			cacheTime: getMilliFromMinutes(0.5),
		}
	)
}

export default useBalance
