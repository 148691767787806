import { SkeletonCircle, SkeletonText, useDisclosure } from '@chakra-ui/react'
import { useAppContext } from '@components/app-context'
import { useAppSetters } from '@components/contexts/AppProvider'
import { Avatar, Button, Disclaimer, Modal, Tag, UserCircles } from '@components/core'
import { CheckIcon, PreviewImageIcon } from '@components/icons'
import { createToast } from '@components/Toasts'
import { Memberships__factory } from '@contracts/abis/types'
import useIsMember from '@hooks/query/membership/useIsMember'
import useProfile from '@hooks/query/useProfile'
import useAddOrChangeChain from '@hooks/useAddOrChangeChain'
import useBalance from '@hooks/useBalance'
import useContractWrite from '@hooks/useContractWrite'
import useCurrentProfile from '@hooks/useCurrentProfile'
import useCurrentUser from '@hooks/useCurrentUser'
import useProvider, { ProviderType } from '@hooks/useProvider'
import useTokenDecimals from '@hooks/useTokenDecimals'
import { useTokenDisplayName } from '@hooks/useTokenDisplayName'
import * as Sentry from '@sentry/nextjs'
import clsx from 'clsx'
import { formatUnits, parseEther } from 'ethers/lib/utils'
import { Chain } from 'helpers/chain'
import {
	CustomModalProps,
	InfiniteMembershipsByOwnerResponse,
	SupportedMediaFormats,
	User,
} from 'interfaces/interfaces'
import { FC, useEffect, useState } from 'react'
import { InfiniteData, useQuery, useQueryClient } from 'react-query'
import { customFormatDuration, getMediaType } from 'utils'
import { getMilliFromMinutes } from 'utils/rq-settings'

const toastId = 'PURCHASE_TOAST'

export interface TierCardInfo {
	address: string
	title: string
	price: number
	tokenSymbol: string
}

export interface MembershipData {
	/**
	 * Membership address
	 */
	address?: string
	title?: string
	price?: number

	/**
	 * Token to be used to purchase a membership
	 */
	purchaseTokenAddress?: string

	/**
	 * Details regarding airdrop
	 */
	airdrop?: {
		amount?: number | string
		tokenAddress?: string
		humanreadable?: boolean
	}

	/**
	 * List of benefits
	 */
	benefits?: string[]

	/**
	 * Duration in seconds
	 */
	duration?: number
	network_chain_id?: string
	image?: string

	/**
	 * Number of purchases
	 */
	quantity?: string

	/**
	 * guild name
	 */
	guildName?: string

	/**
	 * Max no. of purchases
	 */
	cap?: string
}

export const TierCard: FC<{
	className?: string
	users?: User[]
	hideButton?: boolean
	header?: string
	shine?: boolean
	fillAvailableHeight?: boolean
	membership: MembershipData
}> = ({ className, users, hideButton = false, header, shine, fillAvailableHeight, membership }) => {
	const {
		network_chain_id,
		address,
		title,
		price,
		benefits,
		guildName,
		airdrop: { amount: airdropAmount, tokenAddress, humanreadable },
		quantity: spotsFilled,
		image,
		cap: totalSpots,
		duration,
		purchaseTokenAddress,
	} = membership
	const { chainId } = useAppContext()

	const usersSubset = users ? (users.length > 11 ? users.slice(0, 8) : users) : []

	const { data: profile } = useProfile() // to get the user who owns these memberships
	const { data: loggedInProfile } = useCurrentProfile()

	const isCreator = loggedInProfile?.user.wallet_address === profile?.user?.wallet_address
	const { data: isMember, isLoading: isMemberLoading } = useIsMember({
		address,
		chainId: network_chain_id,
	})

	const { onOpen: onOpenBuyModal, isOpen: isBuyModalOpen, onClose: onCloseBuyModal } = useDisclosure()
	const { data: airdropTokenDisplayName } = useTokenDisplayName({
		address: tokenAddress,
		chainId: Number(network_chain_id),
	})
	const { data: purchaseTokenDisplayName } = useTokenDisplayName({
		address: purchaseTokenAddress,
		chainId: Number(network_chain_id),
	})
	const { data: airdropTokenDecimals } = useTokenDecimals(tokenAddress, undefined, Number(network_chain_id))

	const addChain = useAddOrChangeChain()

	const openBuyModal = async (): Promise<void> => {
		if (chainId !== Number(network_chain_id)) {
			createToast({
				title: 'Incorrect network',
				body: `To buy this membership, please switch to the ${new Chain(
					membership.network_chain_id
				).getChainName()} Network`,
				type: 'warning',
			})
			await addChain(Number(membership.network_chain_id))
			return
		}

		onOpenBuyModal()
	}

	const [imageType, setImageType] = useState<SupportedMediaFormats>()

	useEffect(() => {
		getMediaType(image).then((res) => setImageType(res))
	}, [image])

	return (
		<div className={clsx(className, 'flex items-center flex-col gap-5')}>
			{header && <span className="font-semibold text-[24px] text-color-8">{header}</span>}
			<div
				className={clsx(
					'overflow-hidden relative rounded-2xl w-full sm:w-[372px] flex flex-col bg-color-1 ring-inset ring-1 ring-color-3 divide-y divide-color-3',
					fillAvailableHeight && 'h-full'
				)}
			>
				{/* ROW 1 - NFT pic, title, details, button */}
				<div className="flex w-full flex-col gap-5 p-5">
					{image ? (
						<>
							{imageType !== 'video/mp4' ? (
								<div
									className="rounded-md flex w-full h-72 bg-cover bg-center shrink-0"
									style={{ backgroundImage: `url(${image})` }}
								></div>
							) : (
								<video
									src={image}
									className="rounded-md flex w-full h-72 bg-cover bg-center shrink-0"
									autoPlay
									loop
									muted
								/>
							)}
						</>
					) : (
						<div className="rounded-md flex w-full h-72 gap-2 justify-center items-center border border-color-3">
							<PreviewImageIcon className="w-5 h-5 fill-color-4" />
							<span className="text-[18px] font-semibold text-color-4">NFT</span>
						</div>
					)}
					{title ? (
						<span className="leading-none whitespace-normal font-semibold text-[28px] text-color-8">{title}</span>
					) : (
						<span className="leading-none text-color-6 text-[16px] font-semibold uppercase">title</span>
					)}
					<div className="flex flex-wrap gap-2 justify-between">
						<Tag
							className="font-semibold h-[25px]"
							label={price && purchaseTokenDisplayName ? `${price ?? ''} ${purchaseTokenDisplayName ?? ''}` : 'PRICE'}
							iconLeft={membership.network_chain_id ? new Chain(membership.network_chain_id).getNetworkIcon() : null}
						/>
						<Tag className="font-semibold h-[25px]" label={duration ? customFormatDuration(duration) : 'VALIDITY'} />
						<Tag
							className="font-semibold h-[25px]"
							label={
								!isNaN(Number(spotsFilled)) && !isNaN(Number(totalSpots))
									? `${spotsFilled}/${totalSpots} Sold`
									: 'QUANTITY'
							}
						/>
					</div>
					{!hideButton && (
						<Button
							onClick={openBuyModal}
							className="shrink-0 max-w-full"
							disabled={
								!address || isCreator || !profile || !loggedInProfile || Number(spotsFilled) == Number(totalSpots)
							}
							isLoading={isMemberLoading}
							loadingContent="Loading..."
							isSuccess={isMember}
						>
							Mint {title}
						</Button>
					)}
				</div>
				{/* ROW 2 - Benefits */}
				<div className="flex w-full flex-col gap-5 p-5">
					<span className="text-[13px] leading-none font-semibold text-color-4">BENEFITS</span>
					<div className="flex flex-col gap-[10px]">
						{/* // TODO: improve below */}
						{airdropAmount > 0 ? (
							humanreadable ? (
								<BenefitItem item={`${airdropAmount} ${airdropTokenDisplayName} Airdrop`} />
							) : (
								airdropTokenDisplayName &&
								airdropTokenDecimals && (
									<BenefitItem
										item={`${formatUnits(airdropAmount, airdropTokenDecimals)} ${airdropTokenDisplayName} Airdrop`}
									/>
								)
							)
						) : null}
						{/* NOTE: would be great to have existing guild url here after creation */}
						{guildName ? <BenefitItem item={`Access to ${guildName} Guild`} /> : null}
						{benefits?.length > 0 && benefits.map((benefit, i) => <BenefitItem key={i} item={benefit} />)}
					</div>
				</div>
				{/* ROW 3 - Members */}
				{users?.length > 0 && (
					<div className="flex w-full flex-col gap-5 p-5">
						<span className="text-[13px] leading-none font-semibold text-color-4">MEMBERS</span>
						<div className="flex w-full flex-row justify-between items-center">
							{/* // TODO: implement users view modal?  */}
							<UserCircles clickable={false} customSize="w-[30px] h-[30px]" users={usersSubset} />
							{users.length - usersSubset.length > 0 && (
								<Tag
									className="rounded-full h-[30px] text-[15px] font-medium px-3"
									label={`+ ${users.length - usersSubset.length} More`}
									variant="secondary"
								/>
							)}
						</div>
					</div>
				)}
				{shine && (
					<div className="shine absolute bg-white w-[74px] h-[1156px] -top-72 filter mix-blend-overlay blur-[45px] transform opacity-75 rotate-[-24deg]" />
				)}
			</div>
			{/* Buy Modal */}
			{isBuyModalOpen && <BuyModal membership={membership} isOpen={isBuyModalOpen} onClose={onCloseBuyModal} />}
		</div>
	)
}

interface BuyModalProps extends CustomModalProps {
	membership: MembershipData
}

const BuyModal: FC<BuyModalProps> = ({ membership, onClose, isOpen }) => {
	const { data: profile, isLoading: isProfileLoading } = useProfile() // to get the user who owns these memberships
	const { data: loggedInProfile, isLoading: isLoggedInProfileLoading } = useCurrentProfile()

	const [buyDisclaimerIsChecked, setBuyDisclaimerIsChecked] = useState(false)

	const queryClient = useQueryClient()
	const { fireConfetti } = useAppSetters()

	const { data: purchaseTokenDisplayName } = useTokenDisplayName({
		address: membership.purchaseTokenAddress,
		chainId: Number(membership.network_chain_id),
	})

	const { data: user } = useCurrentUser()

	const [{ loading: isBuying, isSuccessful: isBuySuccessful, canWrite }, writePurchase] = useContractWrite(
		{
			addressOrName: membership.address,
			contractInterface: Memberships__factory.abi,
		},
		{
			successMessage: `Success! You're now a ${membership.title} member`,
			loadingMessage: `Joining ${membership.title}`,
			failedMessage: `Error purchasing tier`,
			onSuccess: async () => {
				// reset the disclaimer check box
				setBuyDisclaimerIsChecked(false)
				fireConfetti()
				await queryClient.invalidateQueries('membership-users')
				await queryClient.invalidateQueries('user-memberships')
				queryClient.setQueryData(
					[
						'isMember',
						{
							address: membership.address,
							chainId: Number(membership.network_chain_id),
							userAddress: user?.publicAddress,
						},
					],
					true
				)

				// Optimistically update the user's membership sold count
				queryClient.setQueryData<InfiniteData<InfiniteMembershipsByOwnerResponse>>(
					['memberships-by-owner', profile?.user.wallet_address],
					(previousData) => ({
						...previousData,
						pages: previousData.pages.map((page) => ({
							...page,
							data: page.data.map((_membership) => ({
								..._membership,
								quantity:
									_membership.address === membership.address
										? String(Number(_membership.quantity) + 1)
										: _membership.quantity,
							})),
						})),
					})
				)

				onClose()
			},
			onSend: async (txHash) => {
				console.log('Hash on Send', txHash)
			},
		}
	)

	const { data: balance } = useBalance()
	const readonlyProvider = useProvider(ProviderType.ReadOnly)

	const { data: hasEnoughBalance, isLoading: isHasEnoughBalanceLoading } = useQuery(
		['has-enough-funds-purchase-membership', membership.address, balance],
		async () => {
			if (!membership || !balance || !readonlyProvider) throw new Error('Missing membership or balance')

			const membershipContract = Memberships__factory.connect(membership.address, readonlyProvider)
			const purchaseAmount = parseEther(membership.price.toString())

			const estimatedGas = await membershipContract.estimateGas.purchase(membership?.address, {
				value: purchaseAmount,
			})

			const purchaseAmountWithGas = estimatedGas.add(purchaseAmount)

			return balance.gte(purchaseAmountWithGas)
		},
		{
			enabled: Boolean(isOpen && membership && balance && readonlyProvider),
			staleTime: getMilliFromMinutes(2.5),
		}
	)

	const onBuy = async (): Promise<void> => {
		try {
			await writePurchase('purchase', {
				args: [loggedInProfile.user.wallet_address],
				overrides: {
					value: parseEther(membership.price.toString()),
				},
				toastId,
			})
		} catch (error) {
			Sentry.captureException(error)
		}
	}

	return (
		<Modal size="md" isOpen={isOpen} onClose={onClose}>
			{isProfileLoading || isLoggedInProfileLoading ? (
				<div className="flex flex-col w-full">
					<SkeletonCircle size="14" />
					<SkeletonText mt="4" noOfLines={4} spacing="4" />
				</div>
			) : (
				<div className="flex flex-col justify-center items-center w-full gap-5">
					<div className="flex flex-row gap-5 items-center">
						<Avatar
							size="sm"
							gradient1={profile?.user?.gradient_1}
							gradient2={profile?.user?.gradient_2}
							profilePicUrl={profile?.user?.profile_pic_url}
						/>
						<span className="text-color-8 text-[24px] font-semibold">Buy {membership.title}</span>
					</div>
					<Disclaimer
						isChecked={buyDisclaimerIsChecked}
						toggleCheckbox={() => setBuyDisclaimerIsChecked((isChecked) => !isChecked)}
						text="I am aware that purchasing this membership cannot be undone"
					></Disclaimer>
					<Button
						onClick={onBuy}
						disabled={!buyDisclaimerIsChecked || hasEnoughBalance === false}
						isSuccess={isBuySuccessful}
						isLoading={isBuying || !canWrite || isHasEnoughBalanceLoading}
						loadingContent={!canWrite || isHasEnoughBalanceLoading ? 'Fetching Details...' : 'Purchasing Tier...'}
					>
						Buy {membership.price ? `for ${membership.price} ${purchaseTokenDisplayName}` : ''}
					</Button>
					{buyDisclaimerIsChecked && hasEnoughBalance === false && (
						<p className="font-normal text-sm text-no -translate-y-2 flex w-full pl-5">
							Insufficient funds to purchase this tier
						</p>
					)}
					<Button className="h-[55px] bg-transparent text-lg" variant="outlined" onClick={onClose}>
						Cancel
					</Button>
				</div>
			)}
		</Modal>
	)
}

const BenefitItem: FC<{ item: string; icon?: JSX.Element }> = ({ item, icon }) => (
	<div className="flex flex-row gap-2 items-start">
		{icon ? (
			icon
		) : (
			<span className="align-baseline w-[15px] h-[15px] rounded-full shrink-0 bg-color-5 flex justify-center items-center">
				<CheckIcon className="w-2 h-2 fill-color-1" />
			</span>
		)}
		<span className="leading-none whitespace-normal text-[16px] text-color-5 font-medium">{item}</span>
	</div>
)
