import { useRouter } from 'next/router'
import { useQuery, UseQueryResult } from 'react-query'
import { getProfile } from '../../helpers/queryFunctions'
import { getStringFromStringOrArray } from '../../helpers/utils'
import { ProfileData } from '../../interfaces/interfaces'
import { getMilliFromMinutes } from '../../utils/rq-settings'

const useProfile = (address?: string): UseQueryResult<ProfileData> => {
	const { query } = useRouter()

	return useQuery(['profile', getStringFromStringOrArray(address ?? query.profile)], getProfile, {
		enabled: !!query,
		staleTime: getMilliFromMinutes(2),
		cacheTime: getMilliFromMinutes(5),
		refetchOnWindowFocus: false,
		retry: false,
	})
}

export default useProfile
