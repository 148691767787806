import { Memberships__factory } from '@contracts/abis/types'
import useCurrentUser from '@hooks/useCurrentUser'
import useProvider, { ProviderType } from '@hooks/useProvider'
import { useQuery, UseQueryResult } from 'react-query'
import { getMilliFromSeconds } from 'utils/rq-settings'

type Props = {
	address: string
	chainId: number | string
	userAddress?: string
}

const useIsMember = ({ address, chainId: network_chain_id, userAddress }: Props): UseQueryResult<boolean> => {
	const { data: user } = useCurrentUser()
	const chainId = Number(network_chain_id)
	const provider = useProvider(ProviderType.ReadOnly, chainId)

	return useQuery(
		['isMember', { address, chainId, userAddress: userAddress || user?.publicAddress }],
		() => {
			const membershipContract = Memberships__factory.connect(address, provider)
			return membershipContract.hasValidToken(userAddress || user?.publicAddress)
		},
		{
			enabled: Boolean((userAddress || user) && address && chainId && provider),
			staleTime: getMilliFromSeconds(30),
			retry: false,
		}
	)
}

export default useIsMember
